<template>
  <popupcommon>
    <div class="popup-main__slide">
      <swiper
        ref="popup"
        :modules="modules"
        :pagination="{
          type: 'fraction',
          clickable: true,
        }"
        navigation
        :initialSlide="index"
        :loop="true"
        :slides-per-view="1"
        :space-between="20"
        v-if="activeSlide && dataImg"
      >
        <swiper-slide v-for="(img, index) in dataImg" :key="index"
          ><img :src="img" alt=""
        /></swiper-slide>
      </swiper>
    </div>
  </popupcommon>
</template>
<script>
import { Navigation, Pagination, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue/swiper-vue";
import popupcommon from "@/components/popup/popupcommon.vue";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";
export default {
  components: {
    Swiper,
    SwiperSlide,
    popupcommon,
  },
  props: ["index", "dataImg"],
  data() {
    return {
      activeSlide: 0,
    };
  },
  watch: {
    index() {
      this.activeSlide = false;
      setTimeout(() => {
        this.activeSlide = true;
      }, 500);
      this.$forceUpdate();
      // this.activeSlide = this.index;
      // this.$forceUpdate();
    },
  },
  setup() {
    // const onSwiper = (swiper) => {
    //   console.log(swiper);
    // };
    // const onSlideChange = () => {
    //   console.log("slide change");
    // };
    return {
      // onSwiper,
      // onSlideChange,
      modules: [Navigation, Pagination, EffectFade],
    };
  },
  methods: {},
};
</script>
