<template>
  <div class="library">
    <div class="category pc">
      <img src="../assets/img/logo/logo-category.svg" alt="" />
      <router-link class="before" :to="'/'">Trang chủ</router-link>
      <span class="tag-mid">/</span>
      <router-link class="before" :to="'/album'">Thư Viện</router-link>
      <span class="tag-mid">/</span>
      <router-link class="before" :to="'/album/' + slug" v-if="name">{{
        name
      }}</router-link>
    </div>
    <div class="library-main">
      <div class="library-main__title" data-aos="fade-up">
        <h5 v-if="name" v-html="name"></h5>
        <h1 v-if="title" v-html="title"></h1>
      </div>
      <div class="library-main__content">
        <div class="list-img">
          <div
            v-masonry="containerId"
            transition-duration="0.3s"
            item-selector=".item"
          >
            <div
              v-masonry-tile
              :class="dataShow.length === 1 ? 'item show-one' : 'item'"
              v-for="(img, index) in dataShow"
              :key="index"
              @click="activePopup(index)"
            >
              <div class="item__bg">
                <img :src="img.path" alt="" />
              </div>
            </div>
          </div>
        </div>
        <div
          class="show-more"
          data-aos="fade-up"
          v-if="currentPage < lastPage"
          @click="showMore()"
        >
          <img src="../assets/img/icons/arrow-right-orange.svg" alt="" />
          <p>{{ trans('frontend.button.show more') }}</p>
        </div>
      </div>
    </div>
    <popupSlide :index="index" :dataImg="dataImgP" />
  </div>
</template>
<script>
/* global $ */
import popupSlide from "@/components/popup/popupSlide.vue";
import LibrariesService from "@/services/LibrariesService";
import { mapGetters } from "vuex";
export default {
  components: {
    popupSlide,
  },
  data() {
    return {
      title: "",
      name: "",
      slug: "",
      listImg: [],
      dataShow: [],
      lastPage: 1,
      currentPage: 1,
      checkShow: true,
      dataImgP: [
        "./img/album/popup-img.png",
        "./img/album/popup-img.png",
        "./img/album/popup-img.png",
        "./img/album/popup-img.png",
        "./img/album/popup-img.png",
        "./img/album/popup-img.png",
        "./img/album/popup-img.png",
        "./img/album/popup-img.png",
        "./img/album/popup-img.png",
        "./img/album/popup-img.png",
      ],
      index: -1,
    };
  },
  computed: {
    ...mapGetters(["locale", "titleAlbum"]),
  },
  watch: {
    "$route.params"() {
      this.dataShow = [];
      this.currentPage = 1;
      this.getDataImg();
      this.setTitle();
      console.log('ahi');
    },
  },
  mounted() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    this.getDataImg();
    this.setTitle();
  },

  methods: {
    activePopup(node) {
      this.index = node;
      $("#popupcommon").removeClass("hide");
      $("#popupcommon").addClass("active");
    },
    setTitle() {
      if (this.titleAlbum && this.locale) {
        this.titleAlbum.forEach((element) => {
          if (this.$route.path.includes(element.slug)) {
            console.log("ahii", element);
            this.title = element[this.locale]?.title;
            this.name = element[this.locale]?.name;
            this.slug = element.slug;
          }
        });
      }
    },
    async getDataImg() {
      await LibrariesService.getListImg(this.$route.query.id, this.currentPage)
        .then((resp) => {
          this.listImg = resp.data.data;
          this.dataShow.push(...this.listImg);
          this.dataImgP = this.dataShow.map((x) => {
            return x.path;
          });
          this.lastPage = resp.data?.meta?.last_page;
          this.currentPage = resp.data?.meta?.current_page;
        })
        .catch(() => {});
    },
    showMore() {
      if (this.currentPage < this.lastPage) {
        this.currentPage += 1;
        this.getDataImg();
      }
    },
  },
};
</script>
