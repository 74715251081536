import http from "@/http/http-common";
const resource = "/api/frontend";
class LibrariesService {
    getAlbum() {
        return http.get(`${resource}/libraries`);
    }
    getListImg(id, page) {
        return http.get(`${resource}/getImageByFolderId?folder_id=${id}&page=${page}`);
    }
}

export default new LibrariesService();